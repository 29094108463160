import React, { SyntheticEvent, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import CopyButton from "src/components/Common/CopyButton";
import FilterSearch from "src/components/Common/FilterSearch";
import Table from "src/components/Common/TableNew";
import TruncatedColumn from "src/components/TruncatedColumn/TruncatedColumn";
import { API_CALL_STATE } from "src/constants";
import { RootState } from "src/store/reducers";
import {
  fetchRoomSessionDetails,
  resetRoomSessionsDetails,
  setDerivedRoomSessionsStateInStore,
} from "src/store/rooms/actions";
import { Button, Flex, Loading, Text } from "@100mslive/roomkit-react";
import { Session } from "../../../types/sessions";
import {
  formattedDate,
  getLocalZone,
  isValidObjectID,
  secondsToHMS,
} from "../../../utils";
import { NoSearchResults, NoSessions } from "../components/RoomDetailsStates";

const zoneAbbr = getLocalZone();

const tableHeaders = [
  "Session ID",
  "Session Status",
  "No. of Peers",
  "Session Duration",
  `Start Time (${zoneAbbr})`,
  `End Time (${zoneAbbr})`,
];

const sessionStatus = { ENDED: "ended", LIVE: "live" };

type Props = { roomId: string };

const RoomSessionDetails: React.FC<Props> = ({ roomId }) => {
  const dispatch = useDispatch();
  const { derivedRoomSessionsState, roomSessionDetails: roomSessions } =
    useSelector((state: RootState) => state.rooms);
  const fetchRoomSessionsStatus = useSelector(
    (state: RootState) => state.rooms.fetchRoomSessionsStatus
  );

  useEffect(() => {
    if (roomId) {
      dispatch(fetchRoomSessionDetails({ roomId }));
    }
    return () => {
      dispatch(resetRoomSessionsDetails());
    };
  }, [roomId, dispatch]);

  const handleScroll = (e: SyntheticEvent) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target as HTMLElement;
    const bottom = Math.floor(scrollHeight - scrollTop) === clientHeight;

    if (bottom) {
      dispatch(fetchRoomSessionDetails({ roomId }));
    }
  };

  const handleInputChange = (val: string) => {
    if (isValidObjectID(val)) {
      dispatch(
        setDerivedRoomSessionsStateInStore({
          id: val,
          last: "",
          seriesEnded: false,
        })
      );
      dispatch(resetRoomSessionsDetails());
      dispatch(fetchRoomSessionDetails({ roomId }));
    } else if (val) {
      dispatch(resetRoomSessionsDetails());
      dispatch(
        setDerivedRoomSessionsStateInStore({
          id: val,
          last: "",
          seriesEnded: false,
        })
      );
    } else {
      dispatch(
        setDerivedRoomSessionsStateInStore({
          id: "",
          last: "",
          seriesEnded: false,
        })
      );
      dispatch(fetchRoomSessionDetails({ roomId }));
    }
  };

  const renderTableRows = (
    rows: Partial<Session & { session_id?: string }>[]
  ) => {
    return rows.map(
      // eslint-disable-next-line complexity
      (session, index: number) => (
        <tr key={session?.session_id || index}>
          <td className="whitespace-nowrap">
            <div className="flex gap-3 items-center whitespace-nowrap">
              <TruncatedColumn
                id={session?.session_id || "-"}
                link={
                  session?.session_id
                    ? `/session-details/${session?.session_id}`
                    : `/sessions`
                }
                onClick={() => {}}
                linkExist={true}
              />
              <CopyButton
                componentId="rooms.details.page"
                btnId="session.id"
                value={session?.session_id || "-"}
                buttonCSS={{ ml: "$5" }}
              />
            </div>
          </td>
          <td>
            <Text
              variant="sm"
              css={{
                textTransform: "capitalize",
                color:
                  session.status === sessionStatus.ENDED
                    ? "$on_surface_medium"
                    : "$alert_error_default",
              }}
            >
              {session?.status || "-"}
            </Text>
          </td>
          <td className="text-textIcon-medEmp">
            {session?.participants || "-"}
          </td>
          <td className="text-textIcon-medEmp">
            {secondsToHMS(session?.duration || "")}
          </td>
          <td className="text-textIcon-medEmp">
            {formattedDate(session?.startTime || "")}
          </td>
          <td className="text-textIcon-medEmp">
            {formattedDate(session?.endTime || "")}
          </td>
        </tr>
      )
    );
  };

  const renderContent = (status: string) => {
    if (status === API_CALL_STATE.IN_PROGRESS && roomSessions.length === 0) {
      return (
        <Flex
          css={{
            mt: "$10",
            border: "$space$px solid $border_default",
            r: "$1",
            w: "100%",
            h: "100%",
            p: "$8",
          }}
          justify="center"
          align="center"
        >
          <Skeleton containerClassName="flex-1" width="100%" height={300} />
        </Flex>
      );
    }
    if (roomSessions.length) {
      return (
        <div
          className="table-responsive overflow-auto border-[1px] border-border-default rounded-lg"
          style={{ maxHeight: "75vh" }}
          onScroll={handleScroll}
        >
          <Table
            tableHeaders={tableHeaders}
            compact
            noHoverEffect
            className="table table-borderless mb-0"
            hideBottomBorders={false}
            centerFinalColumn={false}
            highlightAll={false}
          >
            {renderTableRows(roomSessions)}
          </Table>

          {status === API_CALL_STATE.IN_PROGRESS &&
          !derivedRoomSessionsState.seriesEnded ? (
            <Flex justify="center" css={{ mt: "$4" }}>
              <Button outlined variant="standard" css={{ gap: "$4" }}>
                <Text variant="body1">Loading...</Text>
                <Loading size="24" />
              </Button>
            </Flex>
          ) : (
            <></>
          )}
        </div>
      );
    }
    if (API_CALL_STATE.DONE === status && roomSessions.length === 0) {
      if (derivedRoomSessionsState?.id) {
        return <NoSearchResults />;
      }
      return <NoSessions />;
    }

    return <></>;
  };

  return (
    <Flex direction="column">
      <Flex css={{ w: "100%", mb: "$md" }} justify="between" align="center">
        <Text variant="h5">Room Sessions</Text>
      </Flex>
      <Flex css={{ mb: "$4", position: "relative" }} direction="column">
        <FilterSearch
          placeholder="Search with complete Session ID"
          maxWidth="450px"
          componentId="rooms.details.page"
          inputValue={derivedRoomSessionsState?.id || ""}
          invalidInputErrorString="Please check the room ID entered"
          error={
            fetchRoomSessionsStatus !== API_CALL_STATE.IN_PROGRESS &&
            derivedRoomSessionsState?.id &&
            roomSessions.length === 0
              ? "true"
              : ""
          }
          onInputChange={handleInputChange}
          noFilters
          loading={fetchRoomSessionsStatus === API_CALL_STATE.IN_PROGRESS}
          resetDisabled={undefined}
          applyDisabled={undefined}
          filterValues={undefined}
          onFilterChange={undefined}
          onFilterRemove={undefined}
        />
      </Flex>

      {renderContent(fetchRoomSessionsStatus)}
    </Flex>
  );
};

export default RoomSessionDetails;
